import React from "react";
import { ArrowUp } from "react-feather";

import PropTypes from "prop-types";

const ScrollToTop = ({ stick }) => {
    const onClickHandler = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    return (
        <button
            type="button"
            className="backto-top bg-dark"
            onClick={onClickHandler}
            style={{ opacity: stick ? 1 : 0 }}
        >
            <div style={{ backgroundColor: "white" }}>
                <ArrowUp />
            </div>
        </button>
    );
};
ScrollToTop.propTypes = {
    stick: PropTypes.bool,
};

export default ScrollToTop;
