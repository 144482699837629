import React, { useEffect, useState } from "react";
import ScrollToTop from "@ui/scroll-to-top";
import WhatsAppButton from "@ui/whatsapp-button";

const SideFloatingButtons = () => {
    const [stick, setStick] = useState(false);

    useEffect(() => {
        const scrollHandler = () => {
            const scrollPos = window.pageYOffset;
            if (scrollPos > 300) {
                setStick(true);
            } else {
                setStick(false);
            }
        };

        window.addEventListener("scroll", scrollHandler);
        return () => {
            window.removeEventListener("scroll", scrollHandler);
        };
    }, [stick]);

    return (
        <>
            <ScrollToTop stick={stick} />
            <WhatsAppButton stick={stick} />
        </>
    );
};

export default SideFloatingButtons;
